﻿import './notification.js';

//bind event handler to all checkboxes
document.querySelectorAll('.js-core input[type="checkbox"]').forEach(checkbox => {
    checkbox.addEventListener('click', event => {
        updateChannel(event.target.id, event.target.checked);
    });
});

function updateChannel(channelId, channelActive) {
    disableAllChannelCheckboxes();
    $.post("/LandingPage/UpdateChannelSubscription", { channelId: channelId, selected: channelActive })
        .done(function (result, status) {
            notifySuccess(result.successTitle, result.successMessage);
            enableAllChannelCheckboxes();
        })
        .fail(function(result, status) {
            notifyFailure(result.responseJSON.errorTitle, result.responseJSON.errorMessage);
            $('#' + channelId).prop( "checked", !channelActive );
            enableAllChannelCheckboxes();
        });
}

function disableAllChannelCheckboxes() {
    document.querySelectorAll('.js-core input[type="checkbox"]').forEach(checkbox => {
        checkbox.disabled = true;
    });
}

function enableAllChannelCheckboxes() {
    document.querySelectorAll('.js-core input[type="checkbox"]').forEach(checkbox => {
        checkbox.disabled = false;
    });
}