import '../node_modules/jquery/dist/jquery.min.js';

function notifySuccess(headline, successMessage) {
    if (headline) {
        document.querySelector(".flashmessage h3").innerHTML = headline;    
    }

    if (successMessage) {
        document.querySelector(".flashmessage p").innerHTML = successMessage;    
    }

    document.querySelector(".flashmessage").classList.add("active");

    setTimeout(function () {
        document.querySelector(".flashmessage").classList.remove("active");        
    }, 3000);
}

function notifyFailure(headline, errorMessage) {   
    if (headline) {
        document.querySelector(".flashmessage-failure h3").innerHTML = headline;    
    }

    if (errorMessage) {
        document.querySelector(".flashmessage-failure p").innerHTML = errorMessage;    
    }

    document.querySelector(".flashmessage-failure").classList.add("active");

    document.querySelector(".flashmessage-failure span").addEventListener('click', event => {
        document.querySelector(".flashmessage-failure").classList.remove("active");
    });
}

function dismisFailureMessage() {
    document.querySelector(".flashmessage-failure").classList.remove("active");;        
}

// Add functions to the global namespace
window.notifySuccess = notifySuccess;
window.notifyFailure = notifyFailure;
window.dismisFailureMessage = dismisFailureMessage;