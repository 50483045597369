import '../node_modules/jquery/dist/jquery.min.js';
import '../node_modules/jquery-validation/dist/jquery.validate.min.js';
import '../node_modules/jquery-validation/dist/additional-methods.min.js';
import '../node_modules/jquery-validation-unobtrusive/dist/jquery.validate.unobtrusive.min.js';
import '../node_modules/jquery-ajax-unobtrusive/dist/jquery.unobtrusive-ajax.min.js';
import './pageloading.js';

$.validator.setDefaults({
    errorClass: "input-validation-error",
    highlight: function (element, errorClass) {
        var element = $(element);
        element.addClass(errorClass);
        element.closest('.input').addClass('has-error');
    },
    unhighlight: function (element, errorClass) {
        var element = $(element);
        element.removeClass(errorClass);
        element.closest('.input').removeClass('has-error');        
    },
    submitHandler: function (form) {
        if ($(form).valid()) {
            addPageLoading();
            return true;
        }
    }
});