function addPageLoading() {
    $('body').addClass('page-loading');
};

function removePageLoading() {
    $('body').removeClass('page-loading');
};

// Add functions to the global namespace
window.removePageLoading = removePageLoading;
window.addPageLoading = addPageLoading;