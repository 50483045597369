﻿import './notification.js';
const deleteBtn = document.querySelector('.btn.delete');
const confirmDeleteBtn = document.querySelector('.btn.confirm-delete');
function deleteData() {    

    deleteBtn.classList.add('active');
    confirmDeleteBtn.classList.add('active');    
}

function postDelete() {
    window.addPageLoading();
    $.post("/LandingPage/Delete")
        .done(function (result) {
            deleteBtn.classList.remove('active');
            confirmDeleteBtn.classList.remove('active');
            window.removePageLoading();
            location.href = result;
        })
        .fail(function (result, status) {
            notifyFailure(result.responseJSON.errorTitle, result.responseJSON.errorMessage);
            deleteBtn.classList.remove('active');
            confirmDeleteBtn.classList.remove('active');
            window.removePageLoading();
        })
}

// add event to delete button
if (document.querySelector('.btn.delete')) {
    document.querySelector('.btn.delete').addEventListener('click', function () {
        deleteData();
    });
}

if (document.querySelector('.btn.confirm-delete')) {
    document.querySelector('.btn.confirm-delete').addEventListener('click', event => {

        event.target.disabled = true;
        postDelete();
        event.target.disabled = false;
        //event.target.closest('form').submit();
    });
}